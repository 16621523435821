@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  width: 42%;
  height: auto;
}

@media only screen and (max-width: 700px) {
  .App-logo {
    width: 67%;
    padding-bottom: 0px;
    margin-bottom: 0%;
  }
  .App-link {
    padding-top: 0px;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12rem;
  color: white;
}

.App-link {
  padding-top: 40px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #474141;
}
